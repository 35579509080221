import emarsys from './emarsys'
import own from './own'
import middleware from './middleware'
import { loadImg, clipImage } from 'public/src/pages/common/search_words/components/clipImage/utils.js'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'

class FetchData {
  async getEmarsysData (params) {
    let productInfo = null
    const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
    const requestMenuList = ['mallInfo', 'prices', 'stock', 'vipDiscountPrices', 'baseInfo', 'locateLabels', ...extendAtomicFiles]
    if (params.rowNum == 2) requestMenuList.push('beltLabel') 
    try {
      productInfo = await emarsys().getData(params)
      // emarsys 推荐先去请求mall信息，否则后续的价格等信息将无法获取
      // if (productInfo.products.length > 0) productInfo.products = await middleware.filterGoods(productInfo.products)
      const list = await middleware.getGoodsInfo({ 
        items: productInfo.products, 
        requestMenuList,
        rowNum: params.rowNum || 2,
        pageKey: params.pageKey || '',
        itemConfig: params.itemConfig || {},
      })
      // filter out of stock goods
      productInfo.products = middleware.filterGoods(list)

    } catch (e) {
      console.error(e)
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        dataType: 'emarsys'
      }
    }
    return productInfo
  }
  async getOwnData (params) {
    let productInfo = null
    const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
    let requestMenuList = ['prices', 'vipDiscountPrices', 'stock', 'locateLabels', ...extendAtomicFiles]
    if (params.rowNum == 2) requestMenuList.push('beltLabel')
    try {
      const rowNum = params.rowNum || 2
      const subPageKey = rowNum == 2 ? 'other_recommend_2pic' : 'other_recommend_3pic'
      if (params.withAtomic) {
        const fields = getAtomicRequestFileds(params.itemConfig, requestMenuList)
        params.data.atomFields = fields
        params.data.atomScene = {
          sceneKey: rowNum == 2 ? 'TWO_IN_A_ROW' : 'THREE_IN_A_ROW', 
          pageKey: params.pageKey || '',
          subPageKey,
        }
        // 请求推荐带上原子信息请求
        productInfo = await own.fetchData(params)
        // 处理其他信息但是不发起原子请求
        productInfo.products = await middleware.getGoodsInfo({ 
          items: productInfo.products, 
          requestMenuList: [],
          rowNum,
          pageKey: params.pageKey || '',
          subPageKey,
          itemConfig: params.itemConfig || {},
          fields,
          unFetchAtomic: true
        })
      } else {
        productInfo = await own.fetchData(params)
        productInfo.products = await middleware.getGoodsInfo({ 
          items: productInfo.products, 
          requestMenuList,
          rowNum,
          pageKey: params.pageKey || '',
          subPageKey,
          itemConfig: params.itemConfig || {},
        })
      }

    } catch (e) {
      console.error(e)
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        dataType: params.dataType || 'own'
      }
    }
    return productInfo
  }
  async fetchOwnDataForGroup (params) {
    let productInfo = null
    let requestMenuList = ['prices']
    try {
      productInfo = await own.fetchDataForGroup(params)

      productInfo.products = await middleware.getGoodsInfo({ 
        items: productInfo.products, 
        requestMenuList,
        rowNum: params.rowNum || 2,
        pageKey: params.pageKey || '',
        itemConfig: params.itemConfig || {},
      })
    } catch (e) {
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        dataType: params.dataType || 'own'
      }
    }
    return productInfo
  }
  async getProductByImg (params) {
    let originImg = params.data?.image_b64 ||  params.data?.image
    if (!originImg) return console.log('miss image data')

    let productInfo = null
    // let requestMenuList = [
    //   ...new Set([
    //     'prices', 'plusSize'
    //   ].concat(params.listExtField && params.listExtField.length > 0 ? params.listExtField : []))
    // ]
    try {
      // 图片裁剪处理
      const dealImg = async () => {
        const { mw, mh, image } = await loadImg(productInfo.image)
        productInfo.productGroup && productInfo.productGroup.forEach(async (item) => {
          let leftBeginX = item.b0[0] * mw
          let leftBeginY = item.b0[1] * mh
          let clipWidth = item.b1[0] * mw - leftBeginX
          let clipHeight = item.b1[1] * mh - leftBeginY
          const clipInfo = {
            x: leftBeginX,
            y: leftBeginY,
            width: clipWidth,
            height: clipHeight,
          }
          item['point'] = clipInfo
          // eslint-disable-next-line require-atomic-updates
          item['image'] = await clipImage(image, clipInfo)
        })
      }
      productInfo = await own.fetchDataByImg(params)
      let promiseQueue = [
        dealImg(),
      ]

      await Promise.all(promiseQueue)
    } catch (e) {
      console.log(e)
      // eslint-disable-next-line require-atomic-updates
      productInfo = {
        error: 1,
        reason: '请求错误或者超时',
        productGroup: [],
        products: [],
        isFaultTolerant: 1,
        extraInfo: {},
        dataType: params.dataType || 'own',
      }
    }
    return productInfo
  }
}

export {
  FetchData
}
