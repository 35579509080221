import schttp from 'public/src/services/schttp'
import { FetchData } from './fetchData'
// import analysis from './analysis'
import { logicMap } from './emarsys/config'
import emarsys from 'public/src/services/productRecommend/emarsys/index.js'
import { abtservice } from 'public/src/services/abt'

/** 
 *  @param {Array} abtParamsMatchConfig abt解析配置
 *    @param {String} type 解析类型  ['emarsys', 'request']
 *    @param {RegExp} matchRule 解析规则
 *    @param {String | Function} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object ｜ Function} params 接口请求参数
 *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
 *    @param {Function} beforeSendHandler 数据发送前，执行回调
 *    @param {Number} timeout 冗错 超时时间, 一般设置 10000
 * {
 *      type: 'request',
 *      matchRule: /^is_pde=3/,
 *      url: `${ langPath }/product/recommend/getDetailRecommendProducts`,
 *      url: ({abtResult = {}} = {}) => {
 *          return url
 *      },
 *      params: ({abtResult = {}} = {}) => {
 *          return  params
 *      }
 *      params: {
 *          cat_id,
 *          goods_id,
 *          limit: 100,
 *          rule_id: /rule_id=([^&]+)/,
 *          abtInfo: ({abtResult = {}} = {}) => {
 *              return abtResult.expid
 *          }
 *      }
 *  }
 *  @param {Object} paramsMatchConfig 无abt时，解析配置
 *    @param {String} type 解析类型  ['emarsys', 'request']
 *    @param {String} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object} params 接口请求参数
 *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
 *    @param {Function} beforeSendHandler 数据发送前，执行回调
 * 
 *  @param {Object} faultTolerance 容错配置
 *    @param {String} mode 固定 faultTolerance
 *    @param {String} type 解析类型, 枚举['emarsys', 'request']
 *    @param {String} url 当Type为request时处理url字段，请求接口地址
 *    @param {Object} params 接口请求参数
 *    @param {Number} timeout 冗灾 超时时间, 一般设置 30000
 * 
 *  @param {Array} filterGoodsIdList 需要过滤的商品Id
*/
class ProductRecommend {
  constructor ({
    abtParamsMatchConfig = [], 
    paramsMatchConfig = {},
    faultTolerance = null,
    filterGoodsIdList = []
  } = {}) {
    this.fetchData = new FetchData()
    this.abtParamsMatchConfig = abtParamsMatchConfig
    this.paramsMatchConfig = paramsMatchConfig
    this.faultTolerance = !faultTolerance
      ? { type: 'none', params: {}, notFaultTolerance: true } 
      : faultTolerance
    this.filterGoodsIdList = filterGoodsIdList

    // 全场景添加none配置
    abtParamsMatchConfig.push({ type: 'none', matchRule: /^none$/, notFaultTolerance: true })

    // return data
    this.abtInfo = null
    this.matchInfo = null
  }
  // initAnalysis ({
  //   useNewExpose = false,
  //   recommendType = 'list',
  //   layout = '', 
  //   listType = 'recommend-product-list', 
  //   scrollContainer = window,
  //   exposeDaEventId = '',
  //   moduleExposeDaEventId = ''
  // } = {}) {
  //   this.analysis = new analysis({
  //     useNewExpose,
  //     recommendType,
  //     layout,
  //     listType, 
  //     scrollContainer,
  //     exposeDaEventId,
  //     moduleExposeDaEventId
  //   })
  // }
  async doRecommend ({ posKey = '', pageKey = '', itemConfig = {}, pageNum = 1, limit = 100, limitNum = 100, rowNum = 2 } = {}) {
    /** 
     * 花木兰紧急需求，硬编码 begin
    */
    const excludeGoodsId = [1042795, 1042789, 1042788, 1042790, 1042797, 1042792, 1042796, 1042793, 1042791, 1042794]
    if (excludeGoodsId.includes(Number(SaPageInfo.page_param.goods_id))) {
      return {
        recommendInfo: {
          products: [],
          faultTolerant: 0,
          matchInfo: {}
        },
        abtInfo: ''
      }
    }
    /** 
     * 紧急需求，硬编码 end
    */
    let recommendInfo = null
    let matchInfo = null
    
    try {
      if(!this.matchInfo) {
        this.matchInfo = posKey ? await this._getMathInfoByAbtInfo({ posKey }) : this.paramsMatchConfig
      }
      matchInfo = this.matchInfo
      recommendInfo = matchInfo !== null ? await this._featDataByConfig({ matchInfo, itemConfig, pageNum, limit, limitNum, rowNum, pageKey }) : null
      // console.log(posKey || '', 'matchInfo', matchInfo)
      if (recommendInfo) recommendInfo.matchInfo = matchInfo
      // quantity less than ten，trigger fault tolerance
      let limitLength = this.faultTolerance && this.faultTolerance.triggerLimit || 10
      if (!matchInfo || (matchInfo && !matchInfo.notFaultTolerance) && 
        (!recommendInfo || !recommendInfo.products || recommendInfo.products.length < limitLength) && pageNum === 1) {
        // console.log('faultTolerance', this.faultTolerance)
        let faultReason = {
          abtId: '',
          reason: ''
        }
        let reasons = [recommendInfo && recommendInfo.reason || '']

        // 通过传入poskey去匹配请求配置 还是 手动传入请求配置
        if (!matchInfo && !this.abtInfo) {
          reasons.push('无实验')
        } else {
          // 1. 先判断实验
          const abtResult = this.abtInfo || this.paramsMatchConfig.sourceAbtInfo
          if (abtResult) {
            faultReason.abtId = abtResult.expid
            reasons.push(abtResult.param ? '实验参数非空' : '实验参数为空')
          }
          // 2. 在判断是否因为数量不足
          if (recommendInfo && recommendInfo.products && recommendInfo.products.length < limitLength) {
            reasons.push('推荐结果返回数量不足')
          }
        }

        faultReason.reason = reasons.filter(i => !!i).join(';')
        
        recommendInfo = await this._featDataByConfig({ matchInfo: this.faultTolerance, faultReason, rowNum, pageKey, itemConfig })
        recommendInfo.matchInfo = this.faultTolerance
      }

      recommendInfo.products = this.filterList(recommendInfo.products || [], this.filterGoodsIdList)
    } catch(err) {
      console.error(err)
      // console.log('faultTolerance', this.faultTolerance)
      const faultReason = {
        abtId: '',
        reason: '请求错误或超时'
      }
      recommendInfo = await this._featDataByConfig({ matchInfo: this.faultTolerance, faultReason, rowNum, pageKey, itemConfig })
      // console.log('faultTolerance')
      // console.log('recommendInfo', recommendInfo)
      recommendInfo.matchInfo = this.faultTolerance
      recommendInfo.faultTolerant = 1
      recommendInfo.products = this.filterList(recommendInfo.products || [], this.filterGoodsIdList)
    }


    return {
      recommendInfo,
      abtInfo: this.abtInfo
    }
  }

  async _getMathInfoByAbtInfo ({ posKey } = {}) {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtResult = await abtservice.getUserAbtResult({ posKeys: posKey })
    const result = abtResult[posKey]
    if (result && /^emarsys/.test(result.param)) {
      const em = result.param.split('_')
      const emarsysType = em[1] || ''
      if (['related', 'cart'].includes(emarsysType)) {
        await emarsys().logicsCommand(emarsysType)
      }
    }
    // match rule
    let matchInfo = null
    let matchType = ['emarsys', 'request', 'none', 'requestForGroup']
    let hasAbtResult = result instanceof Object && Object.prototype.hasOwnProperty.call(result, 'param')
    if (hasAbtResult) {
      for (let rule of this.abtParamsMatchConfig) {
        if (!matchType.includes(rule.type)) continue
        // 如果匹配规则是正则
        if (rule.matchRule instanceof RegExp) {
          // match abt result
          if (rule.matchRule.test(result.param)) {
            matchInfo = {
              ...rule,
              matchResult: result
            }
            // Take the params from the abtInfo if the matchInfo.type is emarsys
            if (matchInfo.type === 'emarsys') matchInfo['params'] = result.param
            break
          }
        } else if (rule.matchRule instanceof Function) {
          if (rule.matchRule({ abtResult: result || {} })) {
            matchInfo = {
              ...rule,
              matchResult: result
            }
          }
        }
      }
    }

    // get url by fun
    if (matchInfo && matchInfo.url instanceof Function) {
      matchInfo.url = matchInfo.url({ abtResult: result || {} })
    }

    // get params by fun
    if (matchInfo && matchInfo.params instanceof Function) {
      matchInfo.params = matchInfo.params({ abtResult: result || {} })
    }

    // get params field by reg or fun
    Object.keys(matchInfo && matchInfo.params || {}).forEach((item) => {
      let arg = matchInfo.params[item]
      // deal regexp
      if (result && result.param && typeof result.param == 'string' && arg instanceof RegExp) {
        let matchResult = result.param.match(arg)
        matchInfo.params[item] = matchResult && matchResult.length >= 2 ? (matchResult[1] || '') : ''
      }

      // deal fun
      if (arg instanceof Function) {
        let argValue = arg({ abtResult: result || {} })
        matchInfo.params[item] = argValue
      }
    })
    return matchInfo
  }

  async _featDataByConfig ({ pageKey, itemConfig, rowNum = 2, matchInfo = {}, pageNum = 1, limit = 100, limitNum = 100, faultReason } = {}) {
    let recommendInfo = {}
    matchInfo.beforeSendHandler && matchInfo.beforeSendHandler()
    const method = matchInfo.method || 'GET'
    // 容错
    const withAtomic = !faultReason && matchInfo.atomic

    switch(matchInfo.type) {
      case 'emarsys':
        // emarsys没有分页，请求数据时limit取limitNum总数
        recommendInfo = await this.fetchData.getEmarsysData({ type: method, itemConfig, pageKey, rowNum, logic: logicMap.get(matchInfo.params), timeout: matchInfo.timeout || 10000, limit: limitNum <= 100 ? limitNum : 100 })
        break
      case 'request':
        // eslint-disable-next-line no-case-declarations
        let data = matchInfo.params
        if (faultReason) {
          data['faultReason'] = JSON.stringify(faultReason)
        }
        recommendInfo = await this.fetchData.getOwnData({ withAtomic, type: method, itemConfig, pageKey, rowNum, url: matchInfo.url, data, timeout: matchInfo.timeout || 10000, dataType: matchInfo.dataType || 'own', pageNum: pageNum, limit: limit, limitNum: limitNum })
        break
      case 'requestForGroup':
        recommendInfo = await this.fetchData.fetchOwnDataForGroup({ type: method, itemConfig, pageKey, rowNum, url: matchInfo.url, data: matchInfo.params, timeout: matchInfo.timeout || 10000, dataType: matchInfo.dataType || 'own' })
        break
      case 'none':
        recommendInfo.products = []
        break
      default:
        recommendInfo.products = []
    }
    if (matchInfo.mode === 'faultTolerance') recommendInfo.isFaultTolerant = 1
    return recommendInfo
  }

  filterList (list, filterList) {
    if (filterList && filterList.length <= 0) return list
    return list.filter((item) => {
      return item.goods_id && !filterList.find(i => i == item.goods_id)
    })
  }

  /**
   * 请求类目数据 & 是否走容错
   * @param {*} url 
   * @param {*} params 接口的参数
   * @param {*} notFaultTolerance 是否不走容错逻辑 
   * @param {*} faultTolerance 走容错的配置 
   */
  async fetchCateData({ poskey = '', url, params, notFaultTolerance,  faultTolerance = null }) {
    try {
      let matchInfo = null
      if (poskey) {
        const info = matchInfo = await this._getMathInfoByAbtInfo({ posKey: poskey })
        info?.params && (params = { ...params, ...info.params })
      }

      const tabInfo = await schttp({
        url,
        params,
        timeout: 10000
      })
      // const tabInfo = await request({
      //   url,
      //   data: params,
      //   timeout: 10000
      // })
      let isFaultTolerant = false     // 是否是容错的数据
      let recommendData = tabInfo?.info?.products || []
      // 请求错误 || 数量少于 triggerLimit 
      if ( 
        Number(tabInfo.code) !== 0 || 
        !tabInfo.info || 
        !tabInfo.info.products || 
        tabInfo.info.products.length < (faultTolerance && faultTolerance.triggerLimit || 1)
      ) {
        // notFaultTolerance 控制是否走容错
        if (!notFaultTolerance && faultTolerance) {
          let faultReason = {
            abtId: '',
            ruleId: params.rule_id || '',
            reason: ''
          }
          let reasons = []

          // 1. 先判断是否有实验
          if (poskey) {
            if (matchInfo && matchInfo.matchResult) {
              reasons.push(matchInfo.matchResult.param ? '实验参数非空' : '实验参数为空')
              faultReason.abtId = matchInfo.matchResult.expid
            } else {
              reasons.push('无实验')
            }
          }
          // 2. 在判断是否因为数量不足
          const limitLength = faultTolerance && faultTolerance.triggerLimit || 1
          if (tabInfo.info && tabInfo.info.products.products && tabInfo.info.products.length < limitLength) {
            reasons.push('推荐结果返回数量不足')
          }

          faultReason.reason = reasons.filter(i => !!i).join(';')

          const recommendInfo = await this._featDataByConfig({ matchInfo: faultTolerance, faultReason })
          recommendData = recommendInfo.products
          isFaultTolerant = true
        }
      }

      return {
        info: tabInfo?.info || {},
        data: recommendData,
        isFaultTolerant
      }
    } catch (e) {
      let isFaultTolerant = false
      let recommendData = []
      // notFaultTolerance 控制是否走容错
      if (!notFaultTolerance && faultTolerance) {
        const faultReason = {
          ruleId: params.rule_id || '',
          reason: '请求错误或超时'
        }

        const recommendInfo = await this._featDataByConfig({ matchInfo: faultTolerance, faultReason })
        recommendData = recommendInfo.products
        isFaultTolerant = true
      }

      return {
        info: {},
        data: recommendData,
        isFaultTolerant
      }
    }
  }

  updateConfig ({ abtParamsMatchConfig = null, paramsMatchConfig = null, faultTolerance = null } = {}) {
    if (paramsMatchConfig) this.paramsMatchConfig = {
      ...this.paramsMatchConfig,
      ...paramsMatchConfig
    }
    // 更新容错配置
    if (faultTolerance) {
      this.faultTolerance = {
        ...this.faultTolerance,
        ...faultTolerance
      }
    }

    // 更新配置
    if (abtParamsMatchConfig) {
      this.abtParamsMatchConfig = abtParamsMatchConfig
    }
    
  }
}

export default ProductRecommend
