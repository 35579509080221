import schttp from 'public/src/services/schttp'

const http = async(data) => {
  try {
    const res = await schttp(data)
    const { info = {} } = res || {}
    return info
  } catch(e) {
    console.warn(e, '接口错误', data)
    return {}
  }
}

/* 获取推荐列表数据 */
export const getLists = (params) => {
  return http({
    url: `/api/recommend/facade/get`,
    method: 'get',
    params,
  })
} 

/* 兜底推荐容错列表接口 */
export const getInsuranceList = (params) => {
  return http({
    url: `/api/recommend/faultTolerantFacade/get`,
    method: 'get',
    params
  })
} 

/* 获取购物车信息 */
export const getCartInfo = (params) => {
  return http({
    url: '/api/common/cartList/get',
    method: 'get',
    params
  })
}

/* 查询免邮信息 */ 
export const getShippingFreeMall = (data) => {
  return http({
    url: '/api/productInfo/shippingFreeMall/query',
    method: 'post',
    data
  })
}


export const getSiteFreeMallApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/cart/postSimpleFreeMall/get`,
    data
  })
}

export const getCartBriefInfoApi = () => {
  return schttp({
    url: `/api/cart/getCartBriefInfo/get`,
  })
}
// 中间层聚合，api/cart/getCartBriefInfo/get和api/cart/postSimpleFreeMall/get 进行返回
// MR-9157 全场景购物车入口丰富利诱点信息
export const getCartAllInfoApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/cart/getCartAllInfo/get`,
    data
  })
}
