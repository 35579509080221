import middleware from '../middleware'
import schttp from 'public/src/services/schttp'
import { getCookie } from '@shein/common-function'

class OwnRecommend {
  async fetchData(opts = {}) {
    let { url, type = 'GET', data = {}, timeout = 10000, dataType = 'own', pageNum = 1, limit = 100, limitNum = 100 } = opts
    let params = {
      ...{
        limit,
        req_num: limitNum
      },
      ...data,
      ...{
        pageNum,
      }
    }
    try {
      const isDebugger = getCookie('debugger') 
      if (isDebugger) {
        if (url.indexOf('?') == -1) {
          url = `${url}?debugger=1`
        } else {
          const [_url, p] = url.split('?')
          url = `${_url}?debugger=1&${p}`
        }
      }
    } catch (error) {
      console.log(error)
    }
    let results = await schttp({
      url,
      method: type,
      ...(type == 'GET' ? {
        params: params
      } : {
        data: params,
      }),
      timeout
    })
    let list = []
    let responseInfo = {}
    if (results && results.code == 0 && results.info) {
      // 接口数据兼容
      if (results.info.products instanceof Array) list = results.info.products
      if (results.info.list instanceof Array) list = results.info.list
      if (results.info.goods instanceof Array) {
        list = middleware.adapter({ type: 'own', data: results.info.goods })
      }
      responseInfo = results
    } else {
      return Promise.reject(new Error(`url: ${url}: ${results.code}, ${results.msg}`))
    }

    return Promise.resolve({
      products: list,
      dataType,
      isFaultTolerant: 0,
      responseInfo: responseInfo
    })
  }
  async fetchDataForGroup(opts = {}) {
    let { url, type = 'GET', data = {}, timeout = 10000, dataType = 'own' } = opts
    let results = await schttp({
      url,
      method: type,
      ...(type == 'GET' ? {
        params: data
      } : {
        data,
      }),
      timeout
    })
    let list = []
    let responseInfo = {}
    if (results.code == 0 && results.info) {
      responseInfo = results
      // 接口数据兼容
      if (results.info.products instanceof Array) {
        // 打平数据结构
        results.info.products.forEach((groupItem) => {
          if (groupItem && groupItem.ads && groupItem.ads instanceof Array) {
            list.push({
              ...groupItem.ads[0],
              ...{
                label: groupItem.label
              }
            })
          }
        })
      }
    } else {
      return Promise.reject(new Error(`url: ${url}: ${results.code}, ${results.msg}`))
    }

    return Promise.resolve({
      products: list,
      dataType: dataType,
      isFaultTolerant: 0,
      responseInfo: responseInfo
    })
  }
  
  async fetchDataByImg(opts = {}) {
    let { url, type = 'POST', data = {}, timeout = 15000, dataType = 'own' } = opts
    let formData = new FormData()
    data.image && formData.append('image', data.image)
    data.image_b64 && formData.append('image_b64', data.image_b64)
    data.img_b64_type && formData.append('img_b64_type', data.img_b64_type)
    data.req_num && formData.append('req_num', data.req_num)
    data.coordinate && formData.append('coordinate', data.coordinate)
    
    formData.append('scene_id', data.scene_id)
    formData.append('rule_id', data.rule_id || '')
    formData.append('ignore_goods_list', data.ignore_goods_list || [])
    formData.append('result_type', data.result_type ?? '')
    
    let results = await schttp({
      url,
      method: type,
      ...(type == 'GET' ? {
        params: formData
      } : {
        data: formData,
      }),
      timeout
    })
    let list = []
    let listGroup = []
    let responseInfo = {}
    if (results.code == 0 && results.info) {
      responseInfo = results
      // 接口数据兼容
      if (results.info.products instanceof Array) {
        // 打平数据结构
        results.info.products.forEach((groupItem) => {
          if (groupItem && groupItem.ads && groupItem.ads instanceof Array) {
            listGroup.push(groupItem)
            // 对象地址引用不改变
            groupItem.ads.forEach((item) => {
              list.push(Object.assign(item, {
                label: groupItem.label
              }))
            })
          }
        })
      }
    } else {
      return Promise.reject(new Error(`url: ${url}: ${results.code}, ${results.msg}`))
    }

    return Promise.resolve({
      productGroup: listGroup,
      products: list,
      dataType: dataType,
      isFaultTolerant: 0,
      image: responseInfo && responseInfo.info && responseInfo.info.image,
      responseInfo: responseInfo,
      extraInfo: responseInfo?.info?.extraInfo || {},
      apolloConfig: responseInfo?.info?.apolloConfig || {}
    })
  }
}

export default new OwnRecommend()
